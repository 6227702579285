import * as THREE from "three";
import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import Stars from "./3d/Stars";
import Planets from "./3d/Planets";
// import Effects from "./3d/Effects";
import Particles from "./3d/Particles";
import Enemies from "./3d/Enemies";
import Rocks from "./3d/Rocks";
import Explosions from "./3d/Explosions";
import Rings from "./3d/Rings";
import Track from "./3d/Track";
import Ship from "./3d/Ship";
import Rig from "./3d/Rig";
import Hud from "./Hud";
import { Dom } from "./dom/Dom";
import useStore from "./store";

const Game = () => {
  const extras = useStore((state) => state.extras);
  const drones = useStore((state) => state.drones);
  return (
    <>
      {extras ? <Extras /> : null}
      {drones ? <Drones /> : null}
      <Rig>
        <Ship />
      </Rig>
    </>
  );
};

const Extras = () => (
  <>
    <Rocks />
    <Planets />
    <Stars />
    <Particles />
    <Rings />
    <Track />
  </>
);

const Drones = () => (
  <>
    <Enemies />
    <Explosions />
  </>
);

export function App() {
  const { fov } = useStore((state) => state.mutation);
  const actions = useStore((state) => state.actions);
  const gameStarted = useStore((state) => state.gameStarted);
  const backg = useStore((s) => s.backg);

  if (!gameStarted) {
    return <Dom />;
  } else {
    return (
      <>
        <Canvas
          onPointerMove={actions.updateMouse}
          onClick={actions.shoot}
          camera={{ position: [0, 0, 2000], near: 0.01, far: 10000, fov }}
          onCreated={({ gl, camera }) => {
            actions.init(camera);
            gl.gammaInput = true;
            gl.toneMapping = THREE.NoToneMapping;
            {
              (backg === "D") ? gl.setClearColor(new THREE.Color("#020207")) : null;
            }
          }}
        >
          <fog attach="fog" args={["black", 100, 700]} />
          <ambientLight intensity={0.25} />
          <Suspense fallback={null}>{gameStarted ? <Game /> : null}</Suspense>
          {/* <Effects /> */}
        </Canvas>
        <Hud />
      </>
    );
  }
}
