import {
  GAME_NAME,
  GAME_INFO,
  MAIN_MENU_BACKGROUND_COLOR,
} from "../app_config";
import React, { useEffect, useState } from "react";
import "../styles/menu.css";
import logo from "../images/avatar.png";
import flag_pt from "../images/PT.png";
import flag_en from "../images/EN.png";
import fullscr_open from "../images/fullscreen_open.png";
import fullscr_exit from "../images/fullscreen_exit.png";
import useStore from "../store";

const text_pt = [
  "com extras",
  "com drones",
  "(pronunciar sPÁtsa BaTÁlo)",
  "PLAY",
  "RECOMEÇAR",
  "Fundo escuro",
  "Treinar",
  "Let",
  "Me",
];
const text_en = [
  "with extras",
  "with drones",
  "(pronounce sPAtsa BaTAlo)",
  "PLAY",
  "RESTART",
  "Dark",
  "Training",
  "Enter",
  "24",
];

const Drones_on = ({ drones }) => {
  const setDrones = useStore((s) => s.setDrones);
  return (
    <input
      type="checkbox"
      defaultChecked
      onClick={() => setDrones(!drones)}
    ></input>
  );
};

const Drones_off = ({ drones }) => {
  const setDrones = useStore((s) => s.setDrones);
  return <input type="checkbox" onClick={() => setDrones(!drones)}></input>;
};

const Extras_on = ({ extras }) => {
  const setExtras = useStore((s) => s.setExtras);
  return (
    <input
      type="checkbox"
      defaultChecked
      onClick={() => setExtras(!extras)}
    ></input>
  );
};

const Extras_off = ({ extras }) => {
  const setExtras = useStore((s) => s.setExtras);
  return <input type="checkbox" onClick={() => setExtras(!extras)}></input>;
};

export function Menu({ gameStarted }) {
  // const avatar = useStore((state) => state.avatar);

  const setGameStarted = useStore((s) => s.setGameStarted);

  const showMenu = useStore((s) => s.menu);
  const setMenu = useStore((s) => s.setMenu);

  const showInfo = useStore((s) => s.info);
  const setInfo = useStore((s) => s.setInfo);

  const showLinks = useStore((s) => s.links);
  const setLinks = useStore((s) => s.setLinks);

  // const showHowTo = useStore((s) => s.howto);
  // const setHowTo = useStore((s) => s.setHowTo);

  const drones = useStore((s) => s.drones);
  // const setDrones = useStore((s) => s.setDrones);

  const extras = useStore((s) => s.extras);
  // const setExtras = useStore((s) => s.setExtras);

  const sound = useStore((s) => s.sound);
  // const setSound = useStore((s) => s.setSound);

  const toggleSound = useStore((state) => state.actions.toggleSound);

  const [isFullscreen, setIsFullscreen] = useState(false);

  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      // We’re going fullscreen
      console.log("Fullscreen on");
    } else {
      // We’re exiting fullscreen
      console.log("Fullscreen off");
    }
  });

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  let FLAG = "";
  let lang = "";
  const lsl = localStorage.getItem("sblanguage");
  if (lsl !== null && lsl !== "") {
    lang = lsl;
  } else {
    lang = useStore((s) => s.lang);
  }
  const setLang = useStore((s) => s.setLang);

  if (lang === "EN") {
    FLAG = flag_pt;
  } else {
    FLAG = flag_en;
  }

  let auth = "";
  const lsa = localStorage.getItem("sbxpto");
  if (lsa !== null && lsa !== "") {
    auth = lsa;
  }

  let backg = useStore((s) => s.backg);
  const setBackg = useStore((s) => s.setBackg);

  const lsb = localStorage.getItem("sbdarkbackg");
  if (lsb !== null && lsb !== "") {
    backg = lsb;
  }

  let acesso =
    text_pt.slice(7, 8) +
    text_pt.slice(8, 9) +
    text_en.slice(7, 8) +
    "@" +
    text_en.slice(8, 9);

  const handleChange = (event) => {
    backg = event.target.value;
    setBackg(backg);
    localStorage.setItem("sbdarkbackg", backg);
  };

  return (
    <>
      <div
        className={!gameStarted ? `main_menu show ` : "main_menu"}
        style={{
          background: MAIN_MENU_BACKGROUND_COLOR,
        }}
      >
        <div
          className={
            !gameStarted ? `column_container` : "column_container block_all"
          }
        >
          <div className="name_game ">{GAME_NAME}</div>
          <div className="pronounce">
            {lang === "PT" ? text_pt.slice(2, 3) : text_en.slice(2, 3)}
          </div>
          <img alt="logo for the game" className="logo " src={logo} />
          <button
            onClick={() => {
              if (auth == acesso) {
                setGameStarted(true);
                toggleSound(true);
                // setBackg(backg);
                localStorage.setItem("sbdarkbackg", backg);
              } else {
                let auth = prompt("Please enter the access sentence");
                if (auth !== acesso) {
                  window.alert("This game is not available yet");
                } else {
                  localStorage.setItem("sbxpto", acesso);
                  setGameStarted(true);
                  toggleSound(true);
                  // setBackg(backg);
                  localStorage.setItem("sbdarkbackg", backg);
                }
              }
            }}
            className={"play_button"}
          >
            {lang === "PT" ? text_pt.slice(3, 4) : text_en.slice(3, 4)}
            {/* PLAY */}
          </button>
          <label className={"options_label"}>
            {extras === false ? (
              <Extras_off extras={extras} />
            ) : (
              <Extras_on extras={extras} />
            )}
            {lang === "PT" ? text_pt.slice(0, 1) : text_en.slice(0, 1)}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {drones === false ? (
              <Drones_off drones={drones} />
            ) : (
              <Drones_on drones={drones} />
            )}
            {lang === "PT" ? text_pt.slice(1, 2) : text_en.slice(1, 2)}
          </label>
          <label className={"options_label"}>
            {lang === "PT" ? text_pt.slice(5, 6) : text_en.slice(5, 6)}
            &nbsp;
            <select name="backg" defaultValue={backg} onChange={handleChange}>
              <option value="D">Dark</option>
              <option value="C">Cosmos</option>
            </select>
          </label>
          <label className={"options_label"}>
            <button
              name="B1"
              className={"lang_button"}
              onClick={() => {
                {
                  lang === "PT" ? (lang = "EN") : (lang = "PT");
                }
                setLang(lang);
                localStorage.setItem("sblanguage", lang);
                window.location.reload(false);
              }}
            >
              {/* PT */}
              <img src={FLAG} height="20" size="20" alt=""></img>
            </button>
            &nbsp;&nbsp;
            <button
              name="B3"
              className={"info_button"}
              onClick={() => {
                setInfo(!showInfo);
                setMenu(!showMenu);
              }}
            >
              Info
              {/* {lang === "PT" ? back_pt.slice(0, 1) : back_en.slice(0, 1)} */}
            </button>
            &nbsp;&nbsp;
            <button
              name="B4"
              className={"info_button"}
              onClick={() => {
                setLinks(!showLinks);
                setMenu(!showMenu);
              }}
            >
              Links
            </button>
            &nbsp;&nbsp;
            <button
              name="B4"
              className={"fullscr_button"}
              onClick={() => {
                if (!isFullscreen) {
                  document.body.requestFullscreen();
                } else {
                  document.exitFullscreen();
                }
              }}
            >
              <img
                src={!isFullscreen ? fullscr_open : fullscr_exit}
                height="18"
                size="18"
                title="fullscreen"
                alt="fullscreen"
              ></img>
            </button>
          </label>
        </div>
      </div>
    </>
  );
  // };
}
