import { GAME_NAME } from "../app_config";
import "../styles/menu.css";
import useStore  from '../store'
// import game from "../images/blocks.png";

const text_pt = [
  "POLITICA de PRIVACIDADE",
  "Este site não recolhe dados pessoais ou informação de qualquer outra natureza.",
  "Como tal, não processa ou divulga dados e informações de forma alguma.",
  "POLITICA de COOKIES",
  "Este site não usa cookies, apenas utiliza a memória de browser para manter as preferências do jogo.",
  "ISENÇÃO DE RESPONSABILIDADE",
  "Este site foi desenvolvido apenas com fins didáticos e sem qualquer intuito comercial.",
  "PONTO DE PARTIDA",
  "Este site teve como ponto de partida os seguintes projetos:",
];
const text_en = [
  "PRIVACY POLICY",
  "This site does not collect personal data or information of any other nature.",
  "As such, it does not process or disclose data and information in any way.",
  "COOKIES POLICY",
  "This site does not use cookies, only uses browser memory to maintain game preferences.",
  "DISCLAIMER",
  "This site was developed for educational purposes only and without any commercial intent.",
  "STARTING POINT",
  "This site had the following projects as its starting point:",
];

const back_pt = ["Voltar"];

const back_en = ["Back"];

export function Info({ gameStarted }) {
  // const gameOver = useStore((state) => state.gameOver);
  const gameLoaded = true;

  const showMenu = useStore((s) => s.menu);
  const setMenu = useStore((s) => s.setMenu);

  const showInfo = useStore((s) => s.info);
  const setInfo = useStore((s) => s.setInfo);

  const lang = useStore((s) => s.lang);
  // const setLang = useStore((s) => s.setLang);

  return (
    <div className="info show">
      <div className={"column_container"}>
        <div className="name_game_info">{GAME_NAME}</div>
        {/* <button
          name="B8"
          className={"menu_button"}
          onClick={() => {
            setInfo(!showInfo);
            setMenu(!showMenu);
          }}
        >
          Menu
        </button> */}
        <button
          onClick={() => {
            setInfo(!showInfo);
            setMenu(!showMenu);
          }}
          className={"play_button_info"}
        >
          {lang === "PT" ? back_pt.slice(0, 1) : back_en.slice(0, 1)}
        </button>
        <p>
          <a
            href="https://resolution.pt/"
            target="_blank"
            rel="noreferrer"
            title="Resolution - Consultores Informáticos, Lda"
          >
            <i>Resolution - Consultores Informáticos, Lda.</i>
          </a>
        </p>
        <div className="info_text">
          {lang === "PT" ? text_pt.slice(0, 1) : text_en.slice(0, 1)}
          <br />
          {lang === "PT" ? text_pt.slice(1, 2) : text_en.slice(1, 2)}
          <br />
          {lang === "PT" ? text_pt.slice(2, 3) : text_en.slice(2, 3)}

          <p>
            {lang === "PT" ? text_pt.slice(3, 4) : text_en.slice(3, 4)}
            <br />
            {lang === "PT" ? text_pt.slice(4, 5) : text_en.slice(4, 5)}
          </p>
          <p>
            {lang === "PT" ? text_pt.slice(5, 6) : text_en.slice(5, 6)}
            <br />
            {lang === "PT" ? text_pt.slice(6, 7) : text_en.slice(6, 7)}
          </p>
          {/* <p>{lang === "PT" ? text_pt.slice(7, 8) : text_en.slice(7, 8)}</p> */}
          {lang === "PT" ? text_pt.slice(8, 9) : text_en.slice(8, 9)}
          <br />
          <a
            href="https://codesandbox.io/s/react-three-fiber-spaceship-game-dx9ow"
            target="_blank"
            rel="noreferrer"
            title="Space Ship"
          >
            <i>3D spaceship shooting game (sandbox)</i>
          </a>
          <br />
          <a
            href="https://reactjsexample.com/a-simple-three-js-game-of-how-does-react-work-together-with-3d-graphics-in-three-js/"
            target="_blank"
            rel="noreferrer"
            title="simple three.js game"
          >
            <i>
              A simple three.js game of how does React work together with 3D
              graphics in three.js
            </i>
          </a>
          <br />
          <a
            href="https://codesandbox.io/p/sandbox/pinball-in-70-lines-rmfcq"
            target="_blank"
            rel="noreferrer"
            title="Pinball"
          >
            <i>Pinball in 70 lines (sandbox)</i>
          </a>
          <br />
          {/* <br /> */}
        </div>
        {/* <img alt="Game" src={game} width="150" /> */}
        <br />
        <button
          onClick={() => {
            setInfo(!showInfo);
            setMenu(!showMenu);
          }}
          className={"play_button_info"}
        >
          {lang === "PT" ? back_pt.slice(0, 1) : back_en.slice(0, 1)}
        </button>
      </div>
    </div>
  );
}
