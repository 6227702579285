import { GAME_NAME } from "../app_config";
import "../styles/menu.css";
import useStore from '../store'
import { BrowserView, MobileView } from "react-device-detect";
// import paddle from "../images/paddle.png";
// import goal_in from "../images/goal_in.png";
// import goal_out from "../images/goal_out.png";
// import dark_bg from "../images/dark.png";
// import warehouse_bg from "../images/warehouse.png";

const text_pt = [
  "O objectivo do jogo é marcar na baliza adversária, defender a nossa baliza e marcar o maior número de pontos possível.",
  "Para controlar a defesa usar o rato. Existe uma guia para auxiliar no controlo.",
  "Para controlar a defesa pressione com o dedo em qualquer ponto do dispositivo perpendicular ao ponto pretendido.",
  "Claro que pode clicar na linha em que se encontra a defesa.",
  "Pode interferir com as colisões, utilzando o botão direito do rato",
  "Pode interferir com as colisões, deslizando 2 dedos em simultâneo na tela",
  "Pode optar por 2 fundos diferentes: Armazém ou Escuro",
];
const text_en = [
  "The objective of the game is to score in the opponent's goal, defend our goal and score as many points as possible.",
  "To control the paddle on the desktop, use the mouse. There is a guide to help with control.",
  "To control the paddle press your finger anywhere on the device perpendicular to the intended point.",
  "Of course, you can click on the line where the paddle is located.",
  "You can interfere with collisions by using the right mouse button",
  "You can interfere with collisions by sliding 2 fingers simultaneously on the screen",
  "You can choose 2 different backgrounds: Warehouse or Dark",
];

const back_pt = ["Voltar"];

const back_en = ["Back"];

export function HowTo({ gameStarted }) {
  // const gameOver = useStore((state) => state.gameOver);
  const gameLoaded = true;

  const showHowTo = useStore((s) => s.howto);
  const setHowTo = useStore((s) => s.setHowTo);

  const showMenu = useStore((s) => s.menu);
  const setMenu = useStore((s) => s.setMenu);

  const lang = useStore((s) => s.lang);
  // const setLang = useStore((s) => s.setLang);

  return (
    <div className={`info show `}>
      <div className={"column_container"}>
        <div className="name_game_info ">{GAME_NAME}</div>
        <button
          onClick={() => {
            setHowTo(!showHowTo);
            setMenu(!showMenu);
          }}
          className={"play_button_info"}
        >
          {lang === "PT" ? back_pt.slice(0, 1) : back_en.slice(0, 1)}
        </button>
        <p>
          <a
            href="https://resolution.pt/"
            target="_blank"
            rel="noreferrer"
            title="Resolution - Consultores Informáticos, Lda"
          >
            <i>Resolution - Consultores Informáticos, Lda.</i>
          </a>
        </p>
        <div className="info_text">
          {lang === "PT" ? text_pt.slice(0, 1) : text_en.slice(0, 1)}
          <br />
          {/* <img alt="Goal Out" src={goal_out} width="50" /> */}
          <br />
          <BrowserView>
            {lang === "PT" ? text_pt.slice(1, 2) : text_en.slice(1, 2)}
            <br />
            {/* <img alt="paddle" src={paddle} width="65" /> */}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <img alt="Goal In" src={goal_in} width="45" /> */}
            <br />
            {lang === "PT" ? text_pt.slice(4, 5) : text_en.slice(4, 5)}
            <br />
          </BrowserView>
          <MobileView>
            {lang === "PT" ? text_pt.slice(2, 3) : text_en.slice(2, 3)}
            <br />
            {lang === "PT" ? text_pt.slice(3, 4) : text_en.slice(3, 4)}
            <br />
            {/* <img alt="paddle" src={paddle} width="65" /> */}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <img alt="Goal In" src={goal_in} width="45" /> */}
            <br />
            {lang === "PT" ? text_pt.slice(5, 6) : text_en.slice(5, 6)}
            <br />
          </MobileView>
          {lang === "PT" ? text_pt.slice(6, 7) : text_en.slice(6, 7)}
          <br />
          {/* <img alt="warehouse" src={warehouse_bg} width="60" /> */}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {/* <img alt="dark" src={dark_bg} width="60" /> */}
        </div>
        <button
          onClick={() => {
            setHowTo(!showHowTo);
            setMenu(!showMenu);
          }}
          className={"play_button_info"}
        >
          {lang === "PT" ? back_pt.slice(0, 1) : back_en.slice(0, 1)}
        </button>
      </div>
    </div>
  );
}
