import { Color } from "three";

export const GAME_NAME = "SPACA BATALO";
export const GAME_INFO = "(spatsa batalo)";

//COLORS
export const MAIN_MENU_BACKGROUND_COLOR =
  "linear-gradient(3deg, rgba(0,0,0,1) 0%, rgba(154,122,246,0) 50%)";
// export const EARTH_EMISSION_COLOR = new Color(200, 200, 100);
// export const ENVIRONMENT_EMISSIVE_COLOR = new Color(0, 0, 255); //the shitty one
// export const MIST_COLOR = new Color("rgba(250, 0, 0, 0.1)"); //rgb + transparency
// export const OBSTACLE_EMISSIVE_COLOR = new Color(255, 215, 200);
// export const TERRAIN_COLOR = new Color(`rgba(255, 255, 255,0.5)`);
// export const TERRAIN_EMISSIVE_COLOR = new Color(`rgb(243, 0, 190)`);
// // export const WALL_COLOR = new Color(`rgb(220,10, 0)`);
// export const WALL_COLOR = new Color(`rgb(220, 225, 227)`);
// export const ORB_EMISSION_COLOR = new Color(`rgb(255, 0, 0)`);
