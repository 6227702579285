import useStore from '../store'
import { Menu } from "./Menu";
import { Info } from "./Info";
import { Links } from "./Links";
import { HowTo } from "./Howto";

export function Dom() {
  const gameStarted = useStore((s) => s.gameStarted);
  // const gameLoaded = useStore((s) => s.loaded);

  const showMenu = useStore((s) => s.menu);
  const showInfo = useStore((s) => s.info);
  const showLinks = useStore((s) => s.links);
  const showHowTo = useStore((s) => s.howto);

  return (
    <>
      <div className="wtf">
        {/* <Info gameStarted={gameStarted} gameLoaded={gameLoaded} /> */}
        {!gameStarted && showMenu ? (
          <Menu gameStarted={gameStarted} />
        ) : null}
        {!gameStarted && showInfo ? (
          <Info gameStarted={gameStarted} />
        ) : null}
        {!gameStarted && showLinks ? (
          <Links gameStarted={gameStarted} />
        ) : null}
        {!gameStarted && showHowTo ? (
          <HowTo gameStarted={gameStarted} />
        ) : null}
      </div>
    </>
  );
}
